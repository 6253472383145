export const getComponents = () => {
  return [
    { name: 'Accordion', icon: '/assets/component-icon.svg', id: 'accordion' },
    { name: 'Action Sheet', icon: '/assets/component-icon.svg', id: 'action-sheet' },
    { name: 'Alert', icon: '/assets/component-icon.svg', id: 'alert' },
    { name: 'Avatar', icon: '/assets/component-icon.svg', id: 'avatar' },
    { name: 'Badge', icon: '/assets/component-icon.svg', id: 'badge' },
    { name: 'Breadcrumbs', icon: '/assets/component-icon.svg', id: 'breadcrumbs' },
    { name: 'Button', icon: '/assets/component-icon.svg', id: 'button' },
    { name: 'Card', icon: '/assets/component-icon.svg', id: 'card' },
    { name: 'Checkbox', icon: '/assets/component-icon.svg', id: 'checkbox' },
    { name: 'Chip', icon: '/assets/component-icon.svg', id: 'chip' },
    { name: 'Content', icon: '/assets/component-icon.svg', id: 'content' },
    { name: 'Date & Time Picker', icon: '/assets/component-icon.svg', id: 'datetime' },
    { name: 'Floating Action Button', icon: '/assets/component-icon.svg', id: 'fab' },
    { name: 'Grid', icon: '/assets/component-icon.svg', id: 'grid' },
    { name: 'Icons', icon: '/assets/component-icon.svg', id: 'icons' },
    { name: 'Infinite Scroll', icon: '/assets/component-icon.svg', id: 'infinite-scroll' },
    { name: 'Input', icon: '/assets/component-icon.svg', id: 'input' },
    { name: 'Item', icon: '/assets/component-icon.svg', id: 'item' },
    { name: 'Item Group', icon: '/assets/component-icon.svg', id: 'item-group' },
    { name: 'List', icon: '/assets/component-icon.svg', id: 'list' },
    { name: 'Loading', icon: '/assets/component-icon.svg', id: 'loading' },
    { name: 'Menu', icon: '/assets/component-icon.svg', id: 'menu' },
    { name: 'Modal', icon: '/assets/component-icon.svg', id: 'modal' },
    { name: 'Navigation', icon: '/assets/component-icon.svg', id: 'nav' },
    { name: 'Note', icon: '/assets/component-icon.svg', id: 'note' },
    { name: 'Picker', icon: '/assets/component-icon.svg', id: 'picker' },
    { name: 'Popover', icon: '/assets/component-icon.svg', id: 'popover' },
    { name: 'Progress Bar', icon: '/assets/component-icon.svg', id: 'progress' },
    { name: 'Radio', icon: '/assets/component-icon.svg', id: 'radio' },
    { name: 'Range', icon: '/assets/component-icon.svg', id: 'range' },
    { name: 'Refresher', icon: '/assets/component-icon.svg', id: 'refresher' },
    { name: 'Reorder', icon: '/assets/component-icon.svg', id: 'reorder' },
    { name: 'Searchbar', icon: '/assets/component-icon.svg', id: 'searchbar' },
    { name: 'Segment', icon: '/assets/component-icon.svg', id: 'segment' },
    { name: 'Select', icon: '/assets/component-icon.svg', id: 'select' },
    { name: 'Skeleton Text', icon: '/assets/component-icon.svg', id: 'skeleton-text' },
    { name: 'Spinner', icon: '/assets/component-icon.svg', id: 'spinner' },
    { name: 'Tabs', icon: '/assets/component-icon.svg', id: 'tabs' },
    { name: 'Text', icon: '/assets/component-icon.svg', id: 'text' },
    { name: 'Thumbnail', icon: '/assets/component-icon.svg', id: 'thumbnail' },
    { name: 'Toast', icon: '/assets/component-icon.svg', id: 'toast' },
    { name: 'Toggle', icon: '/assets/component-icon.svg', id: 'toggle' },
    { name: 'Toolbar', icon: '/assets/component-icon.svg', id: 'toolbar' },
  ];
}
